import { CartesianScaleTypeRegistry, ChartTypeRegistry, ScaleOptionsByType, TooltipItem } from "chart.js";
import { Scatter } from "react-chartjs-2";
import { CommonLang, useLang } from "../../../../lang";
import { COLOR_SCHEME } from "../../../referentials";
import { CommonChartParams } from "../config";

type FormatValue = (value: number) => string | number;

function formatValue(value: number, format: FormatValue | undefined): string | number {
    return format ? format(value) : value;
}

type AxisParams = {
    title: string | Date;
    axisFormat?: FormatValue;
    tooltipFormat?: FormatValue;
};

export type ScatterChartParams = Omit<CommonChartParams, "dataset"> & {
    dataset: {
        x: number | null;
        y: number | null;
        label?: string;
        color?: string;
        size?: number;
    }[];
    xAxis: AxisParams;
    yAxis: AxisParams;
    tooltipMaxLines?: number;
};

type ScaleTitle = ScaleOptionsByType<ChartTypeRegistry["scatter"]["scales"]>["title"];
type ScaleTicks = ScaleOptionsByType<ChartTypeRegistry["scatter"]["scales"]>["ticks"];
type ScaleType = ScaleOptionsByType<ChartTypeRegistry["scatter"]["scales"]>["type"];

function getScale(
    title: string | Date,
    axisFormat: FormatValue | undefined,
    type: keyof CartesianScaleTypeRegistry = "linear"
): {
    beginAtZero: boolean;
    title: Pick<ScaleTitle, "display" | "text">;
    ticks: Pick<ScaleTicks, "callback">;
    type?: ScaleType;
} {
    return {
        beginAtZero: true,
        title: {
            display: true,
            text: typeof title === "string" ? title : title.toDateString()
        },
        ticks: {
            callback: value => (typeof value === "number" ? formatValue(value, axisFormat) : value)
        },
        ...(type && { type })
    };
}

function getDataWithSameCoordinates(dataset: ScatterChartParams["dataset"], x: number, y: number): ScatterChartParams["dataset"] {
    return dataset.filter(set => set.x === x && set.y === y);
}

export function ScatterChart({ dataset, xAxis, yAxis, width, height, tooltipMaxLines = 5 }: ScatterChartParams): JSX.Element {
    const lang = useLang<CommonLang>();
    const data = dataset.map(el => ({
        x: el.x ?? 0,
        y: el.y ?? 0,
        label: el.label ?? "",
        color: el.color ?? COLOR_SCHEME.slate.primary,
        size: el.size ?? 6
    }));
    const colors = data.map(({ color }) => color);

    return (
        <div
            className="flex justify-center items-center"
            style={{
                width: width ?? "100%",
                height: height ?? "auto"
            }}
        >
            <Scatter
                options={{
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            mode: "nearest",
                            axis: "xy",
                            intersect: true,
                            callbacks: {
                                label: (context: TooltipItem<"scatter">) => {
                                    const { label, x, y } = data[context.dataIndex];
                                    return `${label}: (${formatValue(x, xAxis.tooltipFormat)}, ${formatValue(y, yAxis.tooltipFormat)})`;
                                },
                                ...(!!tooltipMaxLines && {
                                    footer: (context: TooltipItem<"scatter">[]) => {
                                        const dataWithSamePoints = getDataWithSameCoordinates(dataset, context[0].parsed.x, context[0].parsed.y);
                                        const dataOffLimit = dataWithSamePoints.splice(tooltipMaxLines);
                                        if (dataOffLimit.length) {
                                            return `(${dataOffLimit.length + 1} ${lang.shared.others})`;
                                        }
                                        return [];
                                    }
                                })
                            },
                            ...(!!tooltipMaxLines && {
                                filter: (_: TooltipItem<"scatter">, index: number, array: TooltipItem<"scatter">[]) => {
                                    const dataWithSamePoints = getDataWithSameCoordinates(dataset, array[0].parsed.x, array[0].parsed.y);
                                    const dataOffLimit = dataWithSamePoints.splice(tooltipMaxLines);
                                    if (dataOffLimit.length) {
                                        return index < tooltipMaxLines - 1;
                                    }
                                    return true;
                                }
                            })
                        }
                    },
                    scales: {
                        x: getScale(xAxis.title, xAxis.axisFormat),
                        y: getScale(yAxis.title, yAxis.axisFormat)
                    }
                }}
                data={{
                    datasets: [
                        {
                            data,
                            pointBackgroundColor: colors,
                            pointBorderColor: colors,
                            pointRadius: data.map(el => el.size)
                        }
                    ]
                }}
            />
        </div>
    );
}
