import { FileInfo } from "@vaultinum/vaultinum-api";
import { get, keyBy, mapValues, merge, uniq, uniqBy } from "lodash";
import { useEffect, useState } from "react";
import {
    AddIcon,
    Button,
    Buttons,
    Column,
    FileTreeRecord,
    FilterProps,
    FiltersSortLocalStorageService,
    isNotNull,
    ListStorageKey,
    SortDirection,
    SortProps,
    Table as TableComponent,
    TableProps,
    Tables,
    TreeTableProps
} from "../../../../../common";
import { FileTree as FileTreeComponent, FileTreeProps } from "../../../components";
import { Layout } from "../Layout";

export type ListLayoutProps<T, U> = {
    list: T[];
    render: (list: T[]) => JSX.Element;
    add?: {
        onAdd: () => void;
        label?: string;
    };
    // Won't have effect if used with searchNavigation
    searchableKeys?: (keyof T | `${string}.${string}`)[];
    filters?: FilterProps<T>[];
    sorts?: SortProps<T>[];
    searchNavigation?: {
        getMatchingItems: (list: T[], searchText: string) => U[];
        onCurrentMatchingItemChange: (item: U | undefined) => void;
    };
    storageKey?: ListStorageKey;
    totalCount?: number;
};

export namespace Layouts {
    export function Table<T extends object, U = unknown, V = unknown>({
        columns,
        data,
        buttons,
        ...props
    }: TableProps<T, U, V> & { buttons?: JSX.Element }): JSX.Element {
        const [searchText, setSearchText] = useState<string>("");
        const [resultsCount, setResultsCount] = useState<number>();
        const [totalCount, setTotalCount] = useState<number>();

        return (
            <Layout
                setSearchText={setSearchText}
                buttons={buttons}
                resultsCount={resultsCount}
                totalCount={totalCount}
                render={
                    <TableComponent<T, U, V>
                        {...props}
                        columns={columns}
                        data={props.onLazyLoad ? [] : data ?? []}
                        searchText={searchText}
                        onFilter={(count, total) => {
                            setResultsCount(count);
                            setTotalCount(total);
                        }}
                    />
                }
            />
        );
    }

    export function TableTree<T extends { children?: T[] }>({ columns, data, buttons, ...props }: TreeTableProps<T> & { buttons?: JSX.Element }): JSX.Element {
        const [searchText, setSearchText] = useState<string>("");
        const [resultsCount, setResultsCount] = useState<number>(0);
        return (
            <Layout
                setSearchText={setSearchText}
                resultsCount={resultsCount}
                totalCount={data?.length}
                buttons={buttons}
                render={<Tables.Tree columns={columns} data={data} searchText={searchText} onFilter={setResultsCount} {...props} />}
            />
        );
    }

    export function FileTree<T extends FileInfo>({
        structure,
        extraColumns = [] as Column<FileTreeRecord<T>>[],
        onExpand,
        onFileNameClicked,
        buttons,
        isTooltipEnabled,
        expandedRows,
        ...props
    }: FileTreeProps<T>): JSX.Element {
        const [searchText, setSearchText] = useState<string>("");
        const [resultsCount, setResultsCount] = useState<number>(0);

        return (
            <Layout
                setSearchText={setSearchText}
                resultsCount={resultsCount}
                totalCount={structure.length}
                buttons={buttons}
                render={
                    <FileTreeComponent
                        structure={structure}
                        extraColumns={extraColumns}
                        onExpand={onExpand}
                        onFileNameClicked={onFileNameClicked}
                        searchText={searchText}
                        onFilter={setResultsCount}
                        isTooltipEnabled={isTooltipEnabled}
                        expandedRows={expandedRows}
                        {...props}
                    />
                }
            />
        );
    }

    export function List<T, U>({
        add,
        searchNavigation,
        searchableKeys,
        filters,
        sorts,
        list,
        render,
        storageKey,
        totalCount
    }: ListLayoutProps<T, U>): JSX.Element {
        const defaultOptions = filters?.reduce<Record<string, string[]>>((acc, filter) => {
            const filterOptions = keyBy(filter.filters, subFilter => subFilter.key);
            const mappedValues = mapValues(filterOptions, subFilter => subFilter.defaultOptions ?? []);
            return { ...acc, ...mappedValues };
        }, {});
        const filtersSortService = storageKey ? new FiltersSortLocalStorageService(storageKey) : undefined;
        const initialFilters = merge(defaultOptions, filtersSortService?.loadFilters()) ?? {};
        const [selectedFilters, setSelectedFilters] = useState<Record<string, unknown[]>>(initialFilters);
        const [selectedSorts, setSelectedSorts] = useState<Record<string, SortDirection> | undefined>(filtersSortService?.loadSorts() ?? {});
        const [matchingItems, setMatchingItems] = useState<U[]>();
        const [currentItemIndex, setCurrentItemIndex] = useState<number>();
        const [searchText, setSearchText] = useState<string>("");

        function onNext(): void {
            if (!matchingItems?.length) {
                return;
            }
            const newIndex = ((currentItemIndex ?? 0) + 1) % matchingItems.length;
            setCurrentItemIndex(newIndex);
            searchNavigation?.onCurrentMatchingItemChange(matchingItems[newIndex]);
        }

        function onPrevious(): void {
            if (!matchingItems?.length) {
                return;
            }
            const newIndex = ((currentItemIndex ?? 0) + matchingItems.length - 1) % matchingItems.length;
            setCurrentItemIndex(newIndex);
            searchNavigation?.onCurrentMatchingItemChange(matchingItems[newIndex]);
        }

        useEffect(() => {
            if (searchText) {
                setMatchingItems(searchNavigation?.getMatchingItems(listFilteredAndSorted, searchText));
                setCurrentItemIndex(0);
                searchNavigation?.onCurrentMatchingItemChange(matchingItems?.[0]);
            } else {
                setMatchingItems(undefined);
                setCurrentItemIndex(undefined);
                searchNavigation?.onCurrentMatchingItemChange(undefined);
            }
        }, [searchText]);

        function onFilterChange(filterKey: string, selectedOptions: (string | null)[]): void {
            setSelectedFilters(prev => ({
                ...prev,
                [filterKey]: selectedOptions
            }));
            filtersSortService?.saveFilter(filterKey, selectedOptions);
        }

        function doSort(updatedSort: Record<string, SortDirection> | undefined): void {
            setSelectedSorts(updatedSort);
            // only one sort for now
            const [key, direction] = Object.entries(updatedSort ?? {})[0];
            filtersSortService?.saveSort(key, direction);
        }

        const listFilteredByText = list.filter(item => {
            if (searchableKeys?.length) {
                return searchableKeys.some(key => String(get(item, key))?.toLowerCase().includes(searchText.toLowerCase()));
            }
            return true;
        });

        function applyFilters(item: T): T | null {
            return Object.entries(selectedFilters).reduce((acc: T | null, [key, value]: [string, unknown[]]) => {
                if (!acc || !value?.length) {
                    return acc;
                }
                const selectedFilter = filters?.find(filter => filter.filters?.some(option => option.key === key));
                if (!selectedFilter) {
                    return acc;
                }
                return selectedFilter.onFilter(acc, value as string[]);
            }, item);
        }

        function applySorts(itemA: T, itemB: T): number {
            const currentSorts = Object.keys(selectedSorts ?? {});
            // only one sort for now
            if (!currentSorts.length) {
                return 0;
            }
            const selectedSort = sorts?.find(sort => sort.key === currentSorts[0]);
            if (!selectedSort) {
                return 0;
            }
            return selectedSort.onSort?.(itemA, itemB, selectedSorts?.[currentSorts[0]]) ?? 0;
        }

        const listFilteredAndSorted = (searchNavigation ? list : listFilteredByText).map(applyFilters).filter(isNotNull).sort(applySorts);

        const resultsCount = searchNavigation ? searchNavigation.getMatchingItems(listFilteredAndSorted, searchText).length : listFilteredAndSorted?.length;

        const filtersWithOptions = filters?.map(filter => ({
            ...filter,
            filters: filter.filters.map(option => ({
                ...option,
                options: uniqBy(option.options, "value"),
                defaultOptions: uniq(selectedFilters[option.key] as string[]),
                onSubmit: (selectedOptions: string[]) => {
                    onFilterChange(option.key, selectedOptions);
                    option.onSubmit?.(selectedOptions);
                }
            }))
        }));

        return (
            <Layout
                render={render(listFilteredAndSorted)}
                setSearchText={setSearchText}
                filters={
                    <>
                        {filtersWithOptions?.map(({ label, filters: filterOptions, hasSearchAndSelectionHelper = true, key }) => (
                            <Buttons.Filters key={key} label={label} filters={filterOptions} hasSearchAndSelectionHelper={hasSearchAndSelectionHelper} />
                        ))}
                        {sorts && <Buttons.Sort sorts={sorts} onSubmit={doSort} defaultSort={selectedSorts} />}
                    </>
                }
                buttons={add && <Button children={add.label} icon={AddIcon} onClick={add.onAdd} data-id="btn-add-item" isLoading={false} />}
                resultsCount={resultsCount}
                totalCount={totalCount ?? list.length}
                {...(searchNavigation && matchingItems?.length && { searchProps: { navigation: { onNext, onPrevious } } })}
            />
        );
    }

    export const Base = Layout;
}
