import { Account } from "@vaultinum/vaultinum-api";
import {
    addQueryParamsToUrl,
    COMPANY_SIZES,
    FilterProps,
    formatAddress,
    formatDate,
    formatDateFromNow,
    getAccountsByDomainId,
    getWhiteLabelDomain,
    Layouts,
    List,
    openNotificationWithIcon,
    RowCard,
    SortDirection,
    Spin,
    useAuthContext,
    useIndustryList,
    useLang,
    useUrlSearch,
    useWhiteLabelContext
} from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountLang } from "../../../lang/AccountLang";
import { URL } from "../../../services";
import DomainOrganisationDetailPage from "./DomainOrganisationDetailPage";

function Row({ label, value }: { label: string; value?: string }): JSX.Element {
    return (
        <div className="flex gap-2">
            <div className="font-light text-grey-primary">{label}:</div>
            <div>{value ?? "-"}</div>
        </div>
    );
}

function OrganisationCard({ account }: { account: Account }): JSX.Element {
    const lang = useLang<AccountLang>();
    const navigate = useNavigate();
    const industryList = useIndustryList(lang.code);
    const [domainName, setDomainName] = useState<string>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        void (async () => {
            try {
                setIsLoading(true);
                if (account.whiteLabelDomainId) {
                    setDomainName((await getWhiteLabelDomain(account.whiteLabelDomainId))?.fqdn);
                }
            } catch (err) {
                openNotificationWithIcon({ type: "error", description: lang.domain.failedToGetDomain });
            } finally {
                setIsLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account.whiteLabelDomainId]);

    const formattedAddress = account.companyAddress ? formatAddress(account.companyAddress) : null;

    const accountInformations = [
        {
            label: lang.domain.title,
            value: domainName
        },
        {
            label: lang.accountInformation.form.companySize,
            value: account.companySize
        },
        {
            label: lang.accountInformation.form.companyIndustry,
            value: industryList.find(industry => industry.value === account.companyIndustry)?.label
        },
        ...(formattedAddress ? [{ label: lang.accountInformation.form.address.title, value: formattedAddress }] : [])
    ].filter(({ value }) => value?.length);

    return (
        <RowCard onClick={() => navigate(addQueryParamsToUrl(URL.domainOrganisations.index, { id: account.id }))}>
            <div className="flex w-full flex-col gap-4">
                <div className="flex w-full justify-between">
                    <div className="font-medium">{account.companyName}</div>
                    <div className="flex items-center gap-2">
                        <div className="text-grey-primary">
                            {lang.date.createdOn(false)} {formatDate(account.creationDate, lang.code)} ({formatDateFromNow(account.creationDate, lang.code)})
                        </div>
                        {isLoading && <Spin size="small" />}
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    {accountInformations.map(({ label, value }) => (
                        <Row key={value} label={label} value={value} />
                    ))}
                </div>
            </div>
        </RowCard>
    );
}

function DomainOrganisationsList(): JSX.Element {
    const lang = useLang<AccountLang>();
    const { selectedAccount } = useAuthContext();
    const { whiteLabelDomain } = useWhiteLabelContext();
    const [organisations, setOrganisations] = useState<Account[]>();
    const industryList = useIndustryList(lang.code);

    useEffect(
        () =>
            getAccountsByDomainId(whiteLabelDomain?.id ?? null, accounts => {
                setOrganisations(accounts.filter(organisation => organisation.id !== selectedAccount?.id));
            }),
        [whiteLabelDomain?.id, selectedAccount?.id]
    );

    const filters: FilterProps<Account>[] = [
        {
            key: "industry",
            label: lang.accountInformation.form.companyIndustry,
            filters: [
                {
                    key: "industry",
                    options: industryList
                }
            ],
            onFilter: (organisation: Account, selectedOptions: string[]) => (selectedOptions.includes(organisation.companyIndustry) ? organisation : null)
        },
        {
            key: "size",
            label: lang.accountInformation.form.companySize,
            filters: [
                {
                    key: "size",
                    options: COMPANY_SIZES.map(size => ({ label: size, value: size }))
                }
            ],
            onFilter: (organisation: Account, selectedOptions: string[]) => {
                return organisation.companySize && selectedOptions.includes(organisation.companySize) ? organisation : null;
            }
        }
    ];

    const sorts = [
        {
            key: "creationDate",
            label: lang.organisations.creationDate,
            onSort: (a: Account, b: Account, direction?: SortDirection) => {
                if (direction === SortDirection.ASCENDING) {
                    return a.creationDate?.getTime() - b.creationDate?.getTime();
                } else {
                    return b.creationDate?.getTime() - a.creationDate?.getTime();
                }
            },
            directionLabel: { asc: lang.shared.ascending, desc: lang.shared.descending }
        },
        {
            key: "alphabetical",
            label: lang.accountInformation.form.companyName,
            onSort: (a: Account, b: Account, direction?: SortDirection) => {
                if (direction === SortDirection.ASCENDING) {
                    return a.companyName?.localeCompare(b.companyName);
                } else {
                    return b.companyName?.localeCompare(a.companyName);
                }
            },
            directionLabel: { asc: "A-Z", desc: "Z-A" }
        }
    ];

    return (
        <Layouts.List
            list={organisations ?? []}
            render={list => <List list={list} render={organisation => <OrganisationCard account={organisation} />} disableAnimation isVirtualized />}
            filters={filters}
            sorts={sorts}
            searchableKeys={["companyName"]}
        />
    );
}

export default function DomainOrganisationsPage(): JSX.Element {
    const { id } = useUrlSearch() as { id: string };
    if (id) {
        return <DomainOrganisationDetailPage />;
    }
    return <DomainOrganisationsList />;
}
