import { SupportedLanguageCode } from "@vaultinum/vaultinum-api";
import { useDebounceFn } from "ahooks";
import classNames from "classnames";
import { noop } from "lodash";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DEFAULT_LANG } from "../../../lang";
import BadgeCard from "../BadgeCard/BadgeCard";
import { Button, ButtonProps } from "../Button";
import { FileIcon, InfoCircleIcon } from "../Icons";
import { Tooltip } from "../Tooltip/Tooltip";
import { ErrorMessage } from "./ErrorMessage";
import { InputProps, TextAreaProps } from "./InputProps";
import { Label } from "./Label";
import "./react-datepicker.css";

function BaseInput({ forwardedRef, disabled, className, ...props }: InputProps & Pick<BaseInputs.Props, "preventEnterKeyPress" | "forwardedRef">) {
    return (
        <input
            {...props}
            ref={forwardedRef}
            value={props.value ?? ""}
            onKeyDown={e => {
                if (props.preventEnterKeyPress && e.key === "Enter") {
                    e.preventDefault();
                }
            }}
            disabled={disabled}
            className={classNames(className, {
                "bg-grey-extra-light text-grey-primary": disabled
            })}
        />
    );
}

function BaseTextArea({
    forwardedRef,
    value,
    disabled,
    className,
    autoSize,
    ...props
}: TextAreaProps & { forwardedRef?: React.RefObject<HTMLTextAreaElement>; autoSize?: boolean }) {
    function adjustTextareaHeight() {
        const textarea = forwardedRef?.current;
        if (textarea) {
            textarea.style.height = "auto";
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    }

    useEffect(() => {
        if (autoSize) {
            adjustTextareaHeight();
        }
    }, [value, autoSize]);

    return (
        <textarea
            {...props}
            ref={forwardedRef}
            value={value}
            disabled={disabled}
            className={classNames(className, "resize-none", {
                "border-grey-light bg-grey-extra-light text-grey-primary cursor-not-allowed focus:border-slate-light": disabled
            })}
        />
    );
}

function getSharedStyles(
    errorMessage?: string,
    textArea?: {
        withFooter?: boolean;
    }
) {
    return classNames("w-full rounded-md focus:outline-none", {
        "border-danger": errorMessage,
        "border-grey-light focus:border-slate-light": !errorMessage,
        "h-8": !textArea,
        "border p-2": !textArea?.withFooter
    });
}

function InputHeader({ label, info, disabled, required }: { label?: React.ReactNode; info?: string; disabled?: boolean; required?: boolean }) {
    return (
        <div
            className={classNames("flex", {
                "justify-between": label && info,
                "justify-end": !label && info
            })}
        >
            {label && <Label disabled={disabled} label={label} required={required} />}
            {info && (
                <Tooltip title={info}>
                    <InfoCircleIcon color="slate" shade="dark" />
                </Tooltip>
            )}
        </div>
    );
}

export namespace BaseInputs {
    export type Props = {
        errorMessage?: string;
        icon?: React.ElementType;
        label?: React.ReactNode;
        help?: React.ReactNode;
        onIconClick?: () => void;
        bordered?: boolean;
        rightChildren?: React.ReactNode;
        innerRightChildren?: React.ReactNode;
        addonAfter?: string;
        preventEnterKeyPress?: boolean;
        "data-id"?: string;
        info?: string;
        forwardedRef?: React.RefObject<HTMLInputElement>;
        isIndeterminate?: boolean;
        debounce?: { wait: number; callback: (e: ChangeEvent<HTMLInputElement>) => void };
        onDebounceStart?: () => void;
        onDebounceEnd?: () => void;
    };
    export type AdditionalTextAreaProps = Pick<Props, "label" | "errorMessage" | "info"> & {
        displayCount?: boolean;
        forwardedRef?: React.RefObject<HTMLTextAreaElement>;
        autoSize?: boolean;
    };

    export type TextAreaWithFooterProps = AdditionalTextAreaProps & {
        footer: {
            items: {
                id: string;
                name: string;
            }[];
            onAttach: () => void;
            onDetach: (id: string) => void;
            onClick: (id: string) => void;
            buttonProps: ButtonProps;
        };
    };

    export type TextProps = Pick<
        InputProps,
        | "placeholder"
        | "value"
        | "onChange"
        | "required"
        | "disabled"
        | "autoComplete"
        | "autoFocus"
        | "defaultValue"
        | "id"
        | "className"
        | "onBlur"
        | "onPaste"
        | "onKeyUp"
    > &
        Props;
    export type SearchProps = InputProps & Props & { navigation?: { onNext: () => void; onPrevious: () => void } };
    export type ChoiceProps = Pick<
        InputProps,
        "value" | "onChange" | "onClick" | "required" | "disabled" | "checked" | "children" | "id" | "name" | "className" | "defaultChecked"
    > &
        Pick<Props, "label" | "errorMessage" | "info" | "debounce" | "isIndeterminate">;
    export type DatePickerProps = Omit<ReactDatePickerProps, "locale"> &
        Pick<Props, "label" | "errorMessage" | "data-id" | "info"> & {
            required?: boolean;
            disabled?: boolean;
            className?: string;
            yearRange?: [number, number];
            prevMonthButtonDisabled?: boolean;
            nextMonthButtonDisabled?: boolean;
            locale?: SupportedLanguageCode;
        };
    export type DatePickerRangeProps = Omit<ReactDatePickerProps<never, true>, "locale"> &
        Pick<Props, "label" | "errorMessage" | "data-id" | "info"> & {
            required?: boolean;
            disabled?: boolean;
            isClearable?: boolean;
            className?: string;
            locale?: SupportedLanguageCode;
        };

    export type InputPhoneValue = {
        countryCode?: string;
        phoneNumber?: string;
    };

    export type PhoneProps = {
        countriesPhoneData: {
            name: string;
            countryCode: string;
        }[];
        value?: InputPhoneValue;
        defaultValue?: InputPhoneValue;
        onChange?: (event: { target: { value: InputPhoneValue } }) => void;
    } & Omit<InputProps, "value" | "defaultValue" | "onChange">;

    export function Text({
        errorMessage,
        icon: Icon,
        label,
        className,
        disabled,
        required,
        help,
        bordered = true,
        onIconClick,
        rightChildren,
        innerRightChildren,
        addonAfter,
        info,
        forwardedRef,
        onChange,
        debounce,
        onDebounceEnd,
        ...props
    }: InputProps & Props) {
        const { run } = useDebounceFn(
            (e: ChangeEvent<HTMLInputElement>) => {
                if (debounce?.callback) {
                    debounce.callback(e);
                    if (onDebounceEnd) {
                        onDebounceEnd();
                    }
                } else {
                    noop(e);
                }
            },
            { wait: debounce?.wait }
        );
        return (
            <div className={classNames("flex flex-col space-y-1", className)}>
                {(label || info) && <InputHeader label={label} info={info} disabled={disabled} required={required} />}
                <div className="flex items-center gap-2">
                    <div className="relative flex flex-1 items-center">
                        <BaseInput
                            {...props}
                            disabled={disabled}
                            className={classNames("h-8 w-full rounded-md p-2 focus:outline-none", {
                                "border border-danger focus:border-danger": errorMessage,
                                "border border-grey-light focus:border-slate-light": bordered && !errorMessage,
                                "border-0": !bordered,
                                "bg-extra-light text-grey-primary": disabled,
                                "text-slate-dark": !disabled
                            })}
                            forwardedRef={forwardedRef}
                            onChange={e => {
                                onChange?.(e);
                                if (debounce?.wait) {
                                    run(e);
                                }
                            }}
                        />
                        {(Icon || addonAfter) && (
                            <div className="absolute right-2 flex items-center space-x-2">
                                {innerRightChildren && <span>{innerRightChildren}</span>}
                                {Icon && !addonAfter && <Icon color={disabled ? "grey" : "slate"} onClick={onIconClick} />}
                                {addonAfter && !Icon && <span className="text-grey-primary">{addonAfter}</span>}
                            </div>
                        )}
                    </div>
                    {rightChildren && <div>{rightChildren}</div>}
                </div>
                {!errorMessage && help && <span className="flex gap-1 text-grey-primary">{help}</span>}
                {errorMessage && <ErrorMessage message={errorMessage} />}
            </div>
        );
    }

    export function Choice({ id, label, disabled, errorMessage, className, isIndeterminate, ...props }: InputProps & Props) {
        const htmlFor = id;
        const inputRef = useRef<HTMLInputElement>(null);

        useEffect(() => {
            if (inputRef.current) {
                inputRef.current.indeterminate = isIndeterminate ?? false;
            }
        }, [isIndeterminate]);
        return (
            <div>
                <div className={classNames("flex items-baseline", { "gap-1.5": !!label }, className)}>
                    <BaseInput
                        {...props}
                        id={id}
                        disabled={disabled}
                        className={classNames("focus:outline-none", {
                            "cursor-not-allowed bg-grey-light": disabled,
                            "cursor-pointer accent-slate-light": !disabled
                        })}
                        forwardedRef={inputRef}
                    />
                    {label && (
                        <label
                            htmlFor={htmlFor}
                            children={label}
                            className={classNames("w-full", {
                                "text-grey-light": disabled,
                                "cursor-pointer": !disabled && htmlFor
                            })}
                        />
                    )}
                </div>
                {errorMessage && <ErrorMessage message={errorMessage} />}
            </div>
        );
    }

    export function TextArea({ disabled, errorMessage, label, className, required, forwardedRef, info, ...props }: TextAreaProps & AdditionalTextAreaProps) {
        const textAreaClassName = getSharedStyles(errorMessage, {
            withFooter: false
        });
        return (
            <div className={classNames("flex flex-col space-y-1", className)}>
                {(label || info) && <InputHeader info={info} label={label} disabled={disabled} required={required} />}
                <BaseTextArea {...props} forwardedRef={forwardedRef} disabled={disabled} className={textAreaClassName} />
                {errorMessage && <ErrorMessage message={errorMessage} />}
            </div>
        );
    }

    export function TextAreaWithFooter({
        disabled,
        errorMessage,
        label,
        className,
        required,
        forwardedRef,
        info,
        footer,
        ...props
    }: TextAreaProps & TextAreaWithFooterProps): JSX.Element {
        const [isFocused, setIsFocused] = useState(false);

        const onFocus = () => setIsFocused(true);
        const onBlur = () => setIsFocused(false);

        const textAreaClassName = getSharedStyles(errorMessage, {
            withFooter: true
        });

        return (
            <div className={classNames("flex flex-col gap-1", className)}>
                {(label || info) && <InputHeader info={info} label={label} disabled={disabled} required={required} />}
                <div
                    className={classNames("w-full border border-grey-light rounded-md p-2", {
                        "border-slate-light focus:border-slate-light": !errorMessage && isFocused,
                        "bg-white": !disabled,
                        "bg-grey-extra-light": disabled
                    })}
                >
                    <BaseTextArea {...props} forwardedRef={forwardedRef} disabled={disabled} className={textAreaClassName} onFocus={onFocus} onBlur={onBlur} />
                    <div className="flex flex-wrap gap-2 p-2 overflow-y-auto max-h-20">
                        {footer.items.map(item => (
                            <BadgeCard
                                key={item.id}
                                id={item.id}
                                name={item.name}
                                Icon={FileIcon}
                                onDelete={footer.onDetach}
                                onClick={footer.onClick}
                                isDisabled={disabled}
                            />
                        ))}
                    </div>
                    <div className="flex h-full px-2 pt-2 border-t">
                        <Button {...footer.buttonProps} type="default" onClick={footer.onAttach} isDisabled={disabled} />
                    </div>
                </div>
            </div>
        );
    }

    export function DatePicker({ label, className, errorMessage, disabled, required, info, locale, ...props }: DatePickerProps) {
        const dateClassName = getSharedStyles(errorMessage);

        const langCode = useMemo(() => {
            return locale || DEFAULT_LANG;
        }, [locale]);

        return (
            <div className={classNames("flex flex-col space-y-1", className)}>
                {(label || info) && <InputHeader info={info} label={label} disabled={disabled} required={required} />}
                <ReactDatePicker {...props} className={dateClassName} data-id={props["data-id"]} locale={langCode} popperClassName="z-50" />
                {errorMessage && <ErrorMessage message={errorMessage} />}
            </div>
        );
    }

    export function RangePicker({ label, className, errorMessage, disabled, required, info, locale, ...props }: DatePickerRangeProps) {
        const rangePickerClassName = getSharedStyles(errorMessage);

        const langCode = useMemo(() => {
            return locale || DEFAULT_LANG;
        }, [locale]);

        return (
            <div className={classNames("flex flex-col space-y-1", className)}>
                {(label || info) && <InputHeader info={info} label={label} disabled={disabled} required={required} />}
                <ReactDatePicker {...props} selectsRange className={rangePickerClassName} popperClassName="z-20" data-id={props["data-id"]} locale={langCode} />
                {errorMessage && <ErrorMessage message={errorMessage} />}
            </div>
        );
    }
}
